import GeneralInformation from "./sections/GeneralInformation";
import FeeScheduleDetails from "./sections/FeeScheduleDetails";
import FeeScheduleAssoc from "./sections/FeeScheduleAssociation";
import Notes from "./sections/Notes";
import SearchPaginationModal from "../../../../../components/SearchPaginationModal";
import Actions from "modules/actions/fee-schedule-action";

import InsuranceCompany from "../../../../../store/models/InsuranceCompany";
import PayorAccounts from "../../../../../store/models/PayorAccount";
import isEqual from "lodash/isEqual";
import isEqualWith from "lodash/isEqualWith";

const actions = new Actions();
export default {
  name: "AddFeeSchedule",
  components: {
    GeneralInformation,
    FeeScheduleDetails,
    FeeScheduleAssoc,
    Notes,
    SearchPaginationModal,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      render: true,
      dialogType: "confirmChange",
      dialogMsg: "",
      isDiscardModalVisible: false,
      openSearchModal: false,
      modalTitle: "Fee Schedule Association",
      type: "ADD",
      clientSearchModalConfig: {
        sectionNote: "Select client(s) to associate the fee schedule with:",
        searchLabel: "Search Client Name",
        modelInstance: PayorAccounts,
        queryName: "payorAccounts",
        relativeUrl: "/payor-accounts/get-payor-accounts",
        searchBy: "payorName",
        limitData: ["payorCode", "payorName", "totalCount"],
      },
      clientHeaders: [
        {
          text: "Client Name",
          align: "left",
          field: "payorName",
          sortable: false,
        },
        {
          text: "Client ID",
          align: "left",
          field: "payorCode",
          sortable: false,
        },
      ],
      payorSearchModalConfig: {
        sectionNote: "Select payor(s) to associate the fee schedule with:",
        searchLabel: "Search Payor Name",
        modelInstance: InsuranceCompany,
        queryName: "insuranceCompanies",
        relativeUrl: "/insurance-company/all-list",
        searchBy: "name",
        limitData: ["name", "insuranceCompanyId", "totalCount"],
      },
      payorHeaders: [
        {
          text: "Payor Name",
          align: "left",
          field: "name",
          sortable: false,
        },
        {
          text: "Payor Acronym",
          align: "left",
          field: "insuranceCompanyId",
          sortable: false,
        },
      ],
      feeScheduleModel: {
        id: "",
        name: "",
        feeId: "",
        heirarchy: "",
        type: {
          label: "",
          value: "",
        },
        adjustmentType: {
          label: "",
          value: "",
        },
        status: null,
        notes: "",
        effectivityDate: "",
        terminationDate: "",
        feeSchedulePricings: [],
      },
      feeScheduleModelCopy: null,
      selectedAssociation: [],
      confirmVal: null,
      prevConfirmVal: null,
    };
  },
  computed: {
    scheduleType() {
      return this.feeScheduleModel.type?.value;
    },
    headerConfig() {
      return this.scheduleType === "PAYOR"
        ? this.payorHeaders
        : this.clientHeaders;
    },
    modalTypeConfig() {
      return this.scheduleType === "PAYOR"
        ? this.payorSearchModalConfig
        : this.clientSearchModalConfig;
    },
  },
  methods: {
    processQueryCollectionPricing() {
      const fees = [...this.feeScheduleModel.feeSchedulePricings].map(
        (pricing) => {
          let params = {
            testId: pricing.test.value,
            cptCodeId: pricing.cptCode.value,
            standardRate: parseFloat(pricing.standardRate || 0),
            specialRate: parseFloat(pricing.adjustmentDiscount || 0),
            reimbursement: parseFloat(pricing.expectedReimbursement || 0),
          };

          if (pricing.modifier.value)
            params["modifier"] = pricing.modifier.value;

          return params;
        }
      );
      const collectionQueries = [];
      fees.forEach((fee) => {
        collectionQueries.push({
          addFeeSchedulePricing: {
            input: {
              type: "VbsFeeSchedulePricingInput!",
              value: fee,
              unincludeToFields: true,
            },
            mutationReturn: ["success"],
          },
        });
      });
      return collectionQueries;
    },

    processParamAssociation(assocType = "INCLUDE") {
      let collectionIds = [];
      this.selectedAssociation.forEach((association) => {
        collectionIds.push(association.id);
      });

      const assocParam = {
        ids: collectionIds,
        associationType: assocType,
      };

      return collectionIds.length > 0 ? assocParam : null;
    },

    processCollectionAssoc(param) {
      return {
        updateAssociation: {
          association: {
            type: "VbsFeeScheduleAssociationInput",
            value: param,
            unincludeToFields: true,
          },
          mutationReturn: ["success"],
        },
      };
    },

    async onSaveAssoc(assoc) {
      try {
        if (this.compareFeeAssoc(assoc, this.selectedAssociation)) {
          this.showNotifyMessage({
            message: `No changes were made.`,
            type: "danger",
          });
          return;
        }

        this.loading = true;
        let collectionQueries = [];
        let removeAssocParam = this.processParamAssociation("EXCLUDE_ALL");
        this.selectedAssociation = [...assoc];
        let addAssocParam = this.processParamAssociation();

        if (removeAssocParam) {
          collectionQueries.push(this.processCollectionAssoc(removeAssocParam));
        }
        if (addAssocParam) {
          collectionQueries.push(this.processCollectionAssoc(addAssocParam));
        }

        let params = {
          variables: {
            id: {
              type: "UUID!",
              value: this.$route.params.id,
              unincludeToFields: true,
            },
          },
          queries: {},
          collectionQueries: collectionQueries,
        };
        await actions.updateFeeSchedule(params);

        this.showNotifyMessage({
          message: "Records has been saved.",
          type: "success",
        });

        this.loading = false;
        this.openSearchModal = false;
        await this.fetchFeeSchedule();
      } catch (e) {
        this.loading = false;
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      } finally {
        this.$refs.searchPagination.canEdit = false;
      }
    },

    setObjectToAssociation(val) {
      this.openSearchModal = false;
      this.selectedAssociation = [...val];
    },

    async onSave() {
      try {
        const generalFormValidate =
          await this.$refs.generalInfo.$refs.form.validateAsync();
        const feesFormValidate =
          await this.$refs.feeScheduleDetails.validateFees();

        if (!generalFormValidate || !feesFormValidate) {
          this.showNotifyMessage({
            message: `Please provide all necessary fields.`,
            type: "danger",
          });
          return;
        }

        if (!this.$refs.feeScheduleDetails.validateCombinations()) {
          this.showNotifyMessage({
            message: `System does not accept similar test id, cpt, and modifier. Please provide a unique value from any of these identifier:  test id, or cpt, or modifier.`,
            type: "danger",
          });
          return;
        }

        let paramGenInfo = {
          feeId: this.feeScheduleModel.feeId,
          name: this.feeScheduleModel.name,
          heirarchy: this.feeScheduleModel.heirarchy,
          type: this.feeScheduleModel.type.value,
          adjustmentType: this.feeScheduleModel.adjustmentType.value,
          effectivityDate: this.$options.filters.getTimeDate(
            this.feeScheduleModel.effectivityDate,
            "YYYY-MM-DD HH:mm:ss",
            false,
            null,
            this.getTimezoneOffset({
              date: this.feeScheduleModel.effectivityDate,
            }),
          ).utc,
          terminationDate: this.feeScheduleModel.terminationDate
            ? this.$options.filters.getTimeDate(
                this.feeScheduleModel.terminationDate,
                "YYYY-MM-DD HH:mm:ss",
                false,
                null,
                {
                  days: 1,
                  ...this.getTimezoneOffset({
                    date: this.feeScheduleModel.terminationDate,
                    minusSeconds: 1,
                  }),
                }
              ).utc
            : null,
          notes: this.feeScheduleModel.notes,
        };

        let feeCollectionQueries = this.processQueryCollectionPricing();
        let paramAssoc = null;
        if (paramGenInfo.type === "CLIENT" || paramGenInfo.type === "PAYOR") {
          paramAssoc = this.processParamAssociation();
          paramGenInfo = { ...paramGenInfo, association: paramAssoc };
        }

        if (
          (paramGenInfo.type === "PAYOR" || paramGenInfo.type === "CLIENT") &&
          paramAssoc === null
        ) {
          this.showNotifyMessage({
            message:
              "Should atleast select 1 Payor/Clients in Fee Schedule Association",
            type: "danger",
          });
          return;
        }

        this.loading = true;

        const params = {
          variables: {
            input: {
              type: "VbsFeeScheduleInput!",
              value: paramGenInfo,
              unincludeToFields: true,
            },
          },
          queries: {},
          collectionQueries: feeCollectionQueries,
        };
        await actions.createFeeSchedule(params);
        this.showNotifyMessage({
          message: "Records has been saved.",
          type: "success",
        });
        this.$router.push("/system-maintenance/fee-schedule/");
      } catch (e) {
        this.loading = false;
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      }
    },
    confirmChange(change) {
      const { __type } = change;
      const title =
        __type === "schedType" ? "Fee Schedule Type" : "Adjustment Type";
      this.dialog = true;
      this.dialogType = "confirmChange";
      this.dialogMsg = `Changing the ${title} will reset the rates entered for Adjustment/Discount. Do you wish to proceed?`;
      this.confirmVal = change;

      this.prevConfirmVal =
        __type === "schedType"
          ? this.feeScheduleModel.type
          : this.feeScheduleModel.adjustmentType;
    },
    resetFeePricing() {
      this.feeScheduleModel.feeSchedulePricings.forEach((freePrice, index) => {
        this.$refs.feeScheduleDetails.$refs[
          `adjustmentDiscount${index}`
        ].$refs.inputRef._events.change[0](0);
      });
    },
    onConfirmModal() {
      this.dialog = false;
      if (this.dialogType == "confirmChange") {
        const { object, __type } = this.confirmVal;
        if (__type === "schedType") {
          this.feeScheduleModel.type = object;
          this.render = false;
          this.$nextTick(() => {
            this.render = true;
          }); 
          this.selectedAssociation = [];
          this.$refs.searchPagination.$refs.searchDataTable.clearSelected();
          this.$refs.searchPagination.updateSelectedString();
         
        } else {
          this.feeScheduleModel.adjustmentType = object;
        }
        this.resetFeePricing();
      } else {
        this.$router.push("/system-maintenance/fee-schedule/");
      }
    },

    onKeepEditing() {
      this.isDiscardModalVisible = false;
      this.$refs.searchPagination.canEdit = true;
    },
    onConfirmDiscard() {
      this.$refs.searchPagination.$refs.searchDataTable.selected = [
        ...this.selectedAssociation,
      ];
      this.$refs.searchPagination.updateSelectedString();
      this.openSearchModal = false;
    },

    onCancelSearchModal(selected) {
      if (!this.compareFeeAssoc(selected, this.selectedAssociation)) {
        this.isDiscardModalVisible = true;
        this.$refs.searchPagination.updateSelectedString();
      } else {
        this.openSearchModal = false;
      }
    },

    onCancelModal() {
      if (this.dialogType == "confirmChange") {
        const { __type } = this.confirmVal;
        if (__type === "schedType") {
          this.$refs.generalInfo.schedType = this.prevConfirmVal;
          this.feeScheduleModel.type = this.prevConfirmVal;
        } else {
          this.$refs.generalInfo.adjustmentType = this.prevConfirmVal;
          this.feeScheduleModel.adjustmentType = this.prevConfirmVal;
        }
      }
      this.dialog = false;
    },
    onClosePage() {
      this.$router.push("/system-maintenance/fee-schedule/");
    },
    onCancelPage() {
      this.dialogType = "pageCancel";
      this.dialogMsg =
        "You haven't saved your entries. Are you sure you want to discard them?";
      if (this.compareFeeScheduleObj()) {
        this.dialog = true;
      } else {
        this.$router.push("/system-maintenance/fee-schedule/");
      }
    },

    compareFeeAssoc(newSelected, currentSelected) {
      const hasChanges = isEqual(
        newSelected.map((item) => item.id),
        currentSelected.map((item) => item.id)
      );
      return hasChanges;
    },

    compareFeeScheduleObj() {
      let hasChanges = false;
      const obj = Object.keys(this.feeScheduleModelCopy);
      obj.forEach((index) => {
        if (index !== "feeSchedulePricings") {
          if (
            !isEqual(
              this.feeScheduleModel[index],
              this.feeScheduleModelCopy[index]
            )
          ) {
            hasChanges = true;
            return;
          }
        } else if (index === "feeSchedulePricings") {
          const feePrices = this.feeScheduleModel[index];
          const feePricesCopy = this.feeScheduleModelCopy[index];
          if (feePrices.length == feePricesCopy.length) {
            feePrices.forEach((feePrice, feeIndex) => {
              const resultOfPrice = isEqualWith(
                feePrice,
                this.feeScheduleModelCopy[index][feeIndex],
                (value, otherValue) => {
                  return value.test === otherValue.test &&
                    value.cptCode === otherValue.cptCode &&
                    value.modifier === otherValue.modifier &&
                    value.standardRate === otherValue.standardRate &&
                    value.billRate === "0.00"
                    ? true
                    : value.billRate === otherValue.billRate &&
                        value.expectedReimbursement ==
                          otherValue.expectedReimbursement;
                }
              );
              if (!resultOfPrice) {
                hasChanges = true;
                return;
              }
            });
          } else {
            hasChanges = true;
          }
        }
      });
      return hasChanges;
    },
    showModal() {
      this.openSearchModal = true;
    },
    renameProperty(obj, newKey, oldKey) {
      Object.defineProperty(
        obj,
        newKey,
        Object.getOwnPropertyDescriptor(obj, oldKey)
      );
      delete obj[oldKey];
    },
    async fetchFeeSchedule() {
      try {
        let result = await actions.getFeeSchedule({
          variables: {
            property: {
              id: {
                type: "UUID!",
                value: this.$route.params.id,
              },
            },
          },
          limitData: [
            "name",
            "feeId",
            "heirarchy",
            this.buildSubQuery("type", ["enum", "label"], false),
            this.buildSubQuery("adjustmentType", ["enum", "label"], false),
            this.buildSubQuery("status", ["enum", "label"], false),
            "effectivityDate",
            "terminationDate",
            this.buildSubQuery("associatedClients,", [
              "payorCode",
              "payorName",
            ]),
            this.buildSubQuery("associatedPayors,", [
              "name",
              "insuranceCompanyId",
            ]),
            this.buildSubQuery("feeSchedulePricings", [
              this.buildSubQuery("test", ["label"]),
              this.buildSubQuery("cptCode", ["code"]),
              this.buildSubQuery("modifier", ["modifierCode"]),
              "standardRate",
              "specialRate",
              "reimbursement",
              "created",
              "lastModified",
            ]),
            "notes",
          ],
        });
        if (result) {
          const {
            type,
            adjustmentType,
            status,
            feeSchedulePricings,
            effectivityDate,
            terminationDate,
          } = result;

          result.terminationDate = this.$options.filters.getTimeDate(
            terminationDate,
            "MM/DD/YYYY"
          ).zone;

          result.effectivityDate = this.$options.filters.getTimeDate(
            effectivityDate,
            "MM/DD/YYYY"
          ).zone;

          [type, adjustmentType, status].forEach((index) => {
            this.renameProperty(index, "value", "enum");
            this.renameProperty(index, "text", "label");
          });

          feeSchedulePricings.forEach((index) => {
            if (index.modifier) {
              this.renameProperty(index.modifier, "label", "modifierCode");
              this.renameProperty(index.modifier, "value", "id");
            }
            if (index.cptCode) {
              this.renameProperty(index.cptCode, "label", "code");
              this.renameProperty(index.cptCode, "value", "id");
            }
            if (index.test) {
              this.renameProperty(index.test, "value", "id");
            }
            this.renameProperty(
              index,
              "expectedReimbursement",
              "reimbursement"
            );
            this.renameProperty(index, "adjustmentDiscount", "specialRate");
            index["isCreated"] = true;
          });

          this.feeScheduleModel = JSON.parse(
            JSON.stringify({
              ...result,
              feeSchedulePricings: result.feeSchedulePricings.map(
                (pricing) => ({
                  ...pricing,
                  adjustmentDiscount: pricing.adjustmentDiscount || 0,
                  expectedReimbursement: pricing.expectedReimbursement || 0,
                  standardRate: pricing.standardRate || 0,
                })
              ),
            })
          );
          const { associatedClients, associatedPayors } = this.feeScheduleModel;
          this.selectedAssociation = associatedClients
            ? [...associatedClients]
            : associatedPayors
            ? [...associatedPayors]
            : [];
        }
      } catch (e) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      }
    },
  },

  async beforeMount() {
    this.type = this.$route.name === "add-fee-schedule" ? "ADD" : "EDIT";
    if (this.type === "EDIT") await this.fetchFeeSchedule();
    else {
      this.feeScheduleModel.feeSchedulePricings.push({
        test: "",
        cptCode: "",
        modifier: "",
        standardRate: "",
        adjustmentDiscount: "",
        billRate: "",
        expectedReimbursement: "",
        loadInitialCurrencyValue: false,
        created: false,
      });
    }

    this.feeScheduleModelCopy = JSON.parse(
      JSON.stringify(this.feeScheduleModel)
    );
  },
};
