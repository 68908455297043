import Actions from "modules/actions/fee-schedule-action.js";
import capitalize from "lodash/capitalize";

import isEqual from "lodash/isEqual";

export default {
  name: "GeneralInfo",

  props: {
    feeSchedule: {
      type: Object,
      require: true,
    },
    type: {
      type: String,
      requre: true,
    },
  },
  data() {
    return {
      isSaving: false,
      canEdit: true,
      dateMenu: false,
      dateMenu1: false,
      isModalVisible: false,
      actions: new Actions(),
      dateValue: "",
      dateValue1: "",
      vbsFeeScheduleTypeEnums: [],
      vbsFeeScheduleAdjustmentTypeEnums: [],
      schedType: null,
      adjustmentType: null,
      feeScheduleCopy: null,
      rules: {
        onlyNumbers: (val) => {
          if (val.length === 0) return true;

          if (val.indexOf(".") != -1) {
            return (
              val.indexOf(".") == -1 || "This field only accepts whole number"
            );
          } else {
            return /^\d+$/.test(val) || "This field only accepts numbers";
          }
        },
        date: [(val) => !val || this.isValidDate(val) || "Invalid date."],
        scheduleId: [
          (val) =>
            this.isMaxLength(val, 10) ||
            "This field must not exceeds more than 10 characters",

          (val) => !val || this.validateIdIfExist(val) || true,
        ],
        scheduleName: (val) =>
          this.isMaxLength(val, 50) ||
          "This field must not exceeds more than 50 characters",
        heirarchy: (val) => {
          if (!/^\d+$/.test(val)) return true;
          if (val.length === 0) return true;
          return (
            (val <= 100 && val != 0 && val > -1) ||
            "this field only accepts number between 1 - 100"
          );
        },
        required: [
          (val) => {
            if (
              val === null ||
              val === undefined ||
              val.length === 0 ||
              val === "" || 
              val?.value === ""
            ) {
              return "This field is required";
            }
            return true;
          },
        ],
        validTerminationDate: (val, effectivityDate) => {
          if (!val) return true;
          const terminationDate = new Date(val);
          const effectDate = new Date(effectivityDate);
          return (
            terminationDate >= effectDate ||
            "Termination date must not be less than the effective date."
          );
        },
      },
    };
  },
  computed: {
    isEditMode() {
      return this.type == "ADD" ? false : true;
    },
    __FeeSchedule: {
      get() {
        return this.feeSchedule;
      },
      set(value) {
        this.$emit("update:feeSchedule", value);
      },
    },
  },
  methods: {
    confirmDropdownType(selected) {
      if (this.feeSchedule.type.value != "")
        this.$emit("confirmChange", { object: selected, __type: "schedType" });
      else this.feeSchedule.type = this.schedType;
    },
    confirmDropdownAdjustment(selected) {
      if (this.feeSchedule.adjustmentType.value != "")
        this.$emit("confirmChange", { object: selected, __type: "adjustment" });
      else this.feeSchedule.adjustmentType = this.adjustmentType;
    },
    async validateIdIfExist(feeScheduleId) {
      if (this.type == "EDIT" && feeScheduleId) return true;
      try {
        let result = await this.actions.getAllFeeSchedules({
          limit: 1,
          offset: null,
          filter: {
            feeId: feeScheduleId,
            enum_patternMatch: "EXACT",
            archive: true,
            logicalOperator: "OR",
          },
          limitData: ["feeId"],
        });
        if (result.length) {
          return "Fee Schedule ID already exists.";
        } else {
          return true;
        }
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while validating name.",
          type: "danger",
        });

        return "Error while validating Fee Schedule ID";
      }
    },
    async getEnum(enumName) {
      try {
        if (!this[`${this.formatToCamelCase(enumName)}s`].length) {
          this[`${this.formatToCamelCase(enumName)}s`] = [
            {
              text: `Fetching ${this.formaText(enumName)}s...`,
              disabled: true,
            },
          ];

          const result = await this.actions.getEnumValues(enumName);

          if (result) {
            this[`${this.formatToCamelCase(enumName)}s`] =
              result.enumValues.map((item) => {
                return {
                  text: capitalize(item.name.toLowerCase().replace("_", "-")),
                  value: item.name,
                };
              });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: `Unable to fetch data.`,
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: `Unable to fetch data.`,
        });
      }
    },
    formaText(value) {
      const result = value.replace(/([A-Z])/g, " $1");
      return result.toLowerCase();
    },
    formatToCamelCase(str) {
      return str.charAt(0).toLowerCase() + str.slice(1);
    },
    isMaxLength(val, max) {
      if (!val) return true;
      else if (val.length === 0) return false;
      else if (max < val.length) return false;
      return true;
    },
    parseDate(date) {
      if (!this.isValidDate(date)) return "";

      const [month, day, year] = date.split("/");
      return month && day && year
        ? `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
        : null;
    },
    formatDate(value, format = "MM/DD/YYYY") {
      if (!value) return "";
      return this.$options.filters.changeDateFormat(value, format);
    },
    closeDateModal() {
      this.__FeeSchedule.effectivityDate = this.formatDate(this.dateValue);
      this.dateMenu = false;
    },
    closeDateModal1() {
      this.__FeeSchedule.terminationDate = this.formatDate(this.dateValue1);
      this.dateMenu1 = false;
    },

    async updateGenInfo() {
      try {
        const generalFormValidate = await this.$refs.form.validateAsync();
        if (!generalFormValidate) {
          this.showNotifyMessage({
            message: `Please provide all necessary fields.`,
            type: "danger",
          });
          return;
        }

        if (!this.compareFeeScheduleObj()) {
          this.showNotifyMessage({
            message: `No changes were made.`,
            type: "danger",
          });
          return;
        }

        this.isSaving = true;

        let collectionQueries = [
          {
            updateName: {
              name: {
                type: "String",
                value: this.__FeeSchedule.name,
              },
              mutationReturn: ["success"],
            },
            updateValidityDates: {
              effectivityDate: {
                type: "String",
                value: this.$options.filters.getTimeDate(
                  this.__FeeSchedule.effectivityDate,
                  "YYYY-MM-DD HH:mm:ss",
                  false,
                  null,
                  this.getTimezoneOffset({
                    date: this.__FeeSchedule.effectivityDate,
                  })
                ).utc,
              },
              terminationDate: {
                type: "String",
                value: this.__FeeSchedule.terminationDate ? this.$options.filters.getTimeDate(
                  this.__FeeSchedule.terminationDate,
                  "YYYY-MM-DD HH:mm:ss",
                  false,
                  null,
                  {
                    days: 1,
                    ...this.getTimezoneOffset({
                      date: this.__FeeSchedule.terminationDate,
                      minusSeconds: 1,
                    }),
                  }
                ).utc : "",
              },
              mutationReturn: ["success", "errors"],
            },
            updateHeirarchy: {
              heirarchy: {
                type: "String",
                value: this.__FeeSchedule.heirarchy,
              },
              mutationReturn: ["success"],
            },
          },
        ];

        let params = {
          variables: {
            id: {
              type: "UUID!",
              value: this.__FeeSchedule.id,
              unincludeToFields: true,
            },
          },
          queries: {},
          collectionQueries: collectionQueries,
        };

        await this.actions.updateFeeSchedule(params);
        this.showNotifyMessage({
          message: "Records has been saved.",
          type: "success",
        });
        this.isSaving = false;
        this.canEdit = false;
        this.feeScheduleCopy = JSON.parse(JSON.stringify(this.__FeeSchedule));
      } catch (err) {
        this.isSaving = false;
        this.showNotifyMessage({
          message: "Problem has occurred while updating data.",
          type: "danger",
        });
      }
    },
    compareFeeScheduleObj() {
      let hasChanges = false;
      const obj = Object.keys(this.feeScheduleCopy);
      obj.forEach((index) => {
        if (
          index != "feeSchedulePricings" &&
          index != "associationPayors" &&
          index != "associationClients"
        ) {
          if (
            !isEqual(this.__FeeSchedule[index], this.feeScheduleCopy[index])
          ) {
            hasChanges = true;
            return;
          }
        }
      });

      return hasChanges;
    },
    confirmDiscardChanges() {
      this.isModalVisible = false;
      this.canEdit = false;
      this.__FeeSchedule = JSON.parse(JSON.stringify(this.feeScheduleCopy));
    },

    cancelEdit() {
      if (this.compareFeeScheduleObj()) {
        this.isModalVisible = true;
      } else {
        this.canEdit = false;
      }
    },
  },

  async beforeMount() {
    if (this.type != "ADD") {
      const enumRequests = [
        this.getEnum("VbsFeeScheduleTypeEnum"),
        this.getEnum("VbsFeeScheduleAdjustmentTypeEnum"),
      ];
      await Promise.all(
        enumRequests.map(async (item) => {
          return await item;
        })
      );
    }
  },
  async created() {
    this.$nextTick(async () => {
      this.adjustmentType = this.feeSchedule.adjustmentType;
      this.schedType = this.feeSchedule.type;
      this.feeScheduleCopy = JSON.parse(JSON.stringify(this.__FeeSchedule));
      await this.$refs.form.resetValidation();
      if (this.isEditMode) {
        this.canEdit = false;
      }
    });
  },
};
