import Actions from "modules/actions/fee-schedule-action.js";

export default {
  name: "Notes",

  props: {
    feeSchedule: {
      type: Object,
      require: true,
    },
    type: {
      type: String,
      requre: true,
    },
  },
  data() {
    return {
      isSaving: false,
      canEdit: true,
      isModalVisible:false,
      notesCopy: "",
      actions: new Actions(),
    };
  },
  computed: {
    isEditMode() {
      return this.type == "ADD" ? false : true;
    },
    __FeeSchedule: {
      get() {
        return this.feeSchedule;
      },
      set(value) {
        this.$emit("update:feeSchedule", value);
      },
    },
  },
  created() {
    this.$nextTick(() => {
      this.notesCopy = this.feeSchedule.notes
      if (this.isEditMode) {
        this.canEdit = false;
      }
    });
  },
  methods: {
    async updateNotes(){
      try {

        const notesFormValidate = await this.$refs.form.validateAsync();
        if (!notesFormValidate) {
          this.showNotifyMessage({
            message: `Please provide all necessary fields.`,
            type: "danger",
          });
          return;
        }
        this.isSaving = true;
        let collectionQueries = [
          {
            updateNotes: {
              notes: {
                type: "String",
                value: this.__FeeSchedule.notes,
              },
              mutationReturn: ["success"],
            },
          },
        ];

        let params = {
          variables: {
            id: {
              type: "UUID!",
              value: this.__FeeSchedule.id,
              unincludeToFields: true,
            },
          },
          queries: {},
          collectionQueries: collectionQueries,
        };

        await this.actions.updateFeeSchedule(params);
        this.showNotifyMessage({
          message: "Records has been saved.",
          type: "success",
        });
        this.isSaving = false;
        this.canEdit = false;
      } catch (err) {
        this.isSaving = false;
        this.showNotifyMessage({
          message: "Problem has occurred while updating data.",
          type: "danger",
        });
      }
    },
    confirmDiscardChanges(){
      this.canEdit= false;
      this.__FeeSchedule.notes = this.notesCopy;
    },
    cancelEdit(){

      if (this.__FeeSchedule.notes  != this.notesCopy) {
        this.isModalVisible = true;
      } else {
        this.canEdit= false;      
      }
    }
  },
};
