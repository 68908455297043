import Test from "store/models/Test";
import Modifier from "store/models/Modifier";
import CPTCode from "store/models/CPTCode";

import Actions from "modules/actions/fee-schedule-action";

const actions = new Actions();

export default {
  name: "FeeScheduleDetails",
  props: {
    feePricing: {
      type: Array,
      require: true,
    },
    adjustmentType: {
      type: Object,
      require: true,
    },
    type: {
      type: String,
      requre: true,
    },
  },
  data() {
    return {
      isSaving: false,
      render: true,
      canEdit: false,
      isModalVisible: false,
      adjustmentRateEnum: {
        percentageRate: "PERCENTAGE_DISCOUNT",
        flatRate: "FLAT_RATE",
        standardRate: "STANDARD",
        none: "NONE",
      },
      rowNumber: -1,
      isLoading: false,
      testModel: Test,
      modifierModel: Modifier,
      cptModel: CPTCode,
      actions: actions,
      feePricingCopy: null,
      rules: {
        notLessZero: (val) => {
          if (parseFloat(val) >= 0) return true;
          return "Must not be less than zero";
        },
        allowablePercentage: (val) => {
          if (
            this.adjustmentType.value != this.adjustmentRateEnum.percentageRate
          )
            return true;
          if (!val) return true;
          else if (parseFloat(val.replace(/,/g, "")) > 100)
            return "Must not be greater than 100%";
          return true;
        },
        allowableAdjustmentRate: (val, standardRate) => {
          if (
            this.adjustmentType.value != this.adjustmentRateEnum.percentageRate
          ) {
            if (parseFloat(val.replace(/,/g, "")) > standardRate) {
              return "Must not be greater than the standard Rate";
            }
            return true;
          }
          return true;
        },
        allowableDigit: (val) => {
          if (
            val &&
            this.adjustmentDiscount != this.adjustmentRateEnum.percentageRate
          ) {
            const newVal = parseFloat(val.replace(/,/g, ""));
            return (
              /^[0-9]{1,10}((\.)[0-9]{0,2}){0,1}$/.test(newVal) ||
              "Must be maximum number of 10 digits"
            );
          }
          return true;
        },
        allowableModifier: (val) => {
          if (!val) return true;

          return val.value.length >= 3
            ? "Max of 2 characters alphanumeric"
            : true;
        },

        required: [
          (val) => {
            if (
              val === null ||
              val === undefined ||
              val.length === 0 ||
              val === ""
            ) {
              return "This field is required";
            }
            return true;
          },
        ],
      },
      headers: [
        {
          text: "Test ID",
          align: "left",
          sortable: false,
        },
        {
          text: "CPT",
          align: "left",
          sortable: false,
        },
        {
          text: "Modifier",
          align: "left",
          sortable: false,
        },
        {
          text: "Standard Rate",
          align: "left",
          sortable: false,
          value: "standardRate",
        },
        {
          text: "Adjustment/Discount",
          align: "left",
          sortable: false,
          value: "adjustmentDiscount",
        },
        {
          text: "Bill Rate",
          align: "left",
          sortable: false,
        },
        {
          text: "Expected Reimbursement",
          align: "left",
          sortable: false,
        },
        {
          text: "",
          sortable: false,
          value: "delete",
        },
      ],
    };
  },
  computed: {
    hasNotCreatedItems() {
      return [...this.__FeePricing].filter(function (index) {
        return !index.isCreated;
      }).length;
    },
    isEditMode() {
      return this.type == "ADD" ? false : true;
    },
    isPrefix() {
      return this.adjustmentType.value ===
        this.adjustmentRateEnum.percentageRate
        ? false
        : true;
    },
    isAdjustmentLock() {
      return this.adjustmentType.value === this.adjustmentRateEnum.none
        ? true
        : false;
    },
    __FeePricing: {
      get() {
        return this.feePricing;
      },
      set(value) {
        this.$emit("update:feePricing", value);
      },
    },
  },
  watch: {
    adjustmentType() {
      this.feePricing.forEach((item, index) => {
        if (this.adjustmentType.value === this.adjustmentRateEnum.none) {
          let resetAmount = parseFloat(0.0).toFixed(2);
          this.$refs[
            `adjustmentDiscount${index}`
          ].$refs.inputRef._events.change[0](resetAmount);
        }
        this.onAmountChange(index, item.standardRate, item.adjustmentDiscount);
      });
    },
  },
  methods: {
    async validateFees() {
      let tableFormValidate = await Promise.all(
        this.__FeePricing.map(async (item, index) => {
          let rows =
            [
              await this.$refs[
                `standardRate${index}`
              ].$refs.form.validateAsync(),
              await this.$refs[`billRate${index}`].$refs.form.validateAsync(),
              await this.$refs[
                `adjustmentDiscount${index}`
              ].$refs.form.validateAsync(),
              await this.$refs[
                `expectedReimbursement${index}`
              ].$refs.form.validateAsync(),
              index == 0 ? await this.$refs.form.validateAsync() : true,
            ].filter((bool) => !bool).length === 0;
          return rows;
        })
      );
      return tableFormValidate.filter((bool) => !bool).length === 0;
    },
    validateCombinations() {
      const feeSchedulePricings = [...this.__FeePricing];
      const foundDuplicateRow = feeSchedulePricings.find((outer, index) => {
        return feeSchedulePricings.find(
          (inner, ind) =>
            inner.test.value === outer.test.value &&
            inner.cptCode.value === outer.cptCode.value &&
            index !== ind &&

            (inner.modifier && outer.modifier ? 
                inner.modifier.value === outer?.modifier.value

              : !inner.modifier && !outer.modifier 
                ? inner.modifier?.value === outer.modifier?.value : false)
        );
      });

      if (foundDuplicateRow) {
        this.rowNumber = feeSchedulePricings.findIndex(
          (inner) =>
            inner.test.value === foundDuplicateRow.test.value &&
            inner.cptCode.value === foundDuplicateRow.cptCode.value &&
            !inner.created &&

            (inner.modifier && foundDuplicateRow.modifier ? 
              inner.modifier.value === foundDuplicateRow?.modifier.value

                : !inner.modifier && !foundDuplicateRow.modifier ? 
                    inner.modifier?.value === foundDuplicateRow.modifier?.value : false)
        );
      }

      if (foundDuplicateRow === undefined) return true;

      return foundDuplicateRow ? false : true;
    },
    validateCreateModifier(searchVal) {
      return (
        /^[a-zA-Z0-9 ]*$/.test(searchVal) ||
        "Modifier only allows alphanumeric characters."
      );
    },
    populateCode(e, index) {
      if (this.objectHasOwnProperty(e, "id")) {
        if (index === this.rowNumber) {
          this.rowNumber = -1;
        }
        const { id, label, code, modifierCode, __typename } = e;
        const updatedObj = {
          value: id,
          label: label ? label : code ? code : modifierCode,
        };
        if (__typename == "Test") {
          this.__FeePricing[index].test = updatedObj;
        } else if (__typename == "Modifier") {
          this.__FeePricing[index].modifier = updatedObj;
        } else {
          this.__FeePricing[index].cptCode = updatedObj;
        }
      }
    },
    clearModifier(index) {
      this.__FeePricing[index].modifier = "";
      this.rowNumber = -1;
    },
    populateCurrencies(item) {
      item.loadInitialCurrencyValue = true;
    },
    formatDate(date) {
      return this.$options.filters.parseDateFromString(date, "MM/DD/YYYY");
    },
    onAmountChange(index, standard, discount) {
      let amount = this.computeDiscount(standard, discount);
      this.__FeePricing[index].billRate = amount;
      this.$refs[`billRate${index}`].$refs.inputRef._events.change[0](amount);
    },
    computeDiscount(standard, discount) {
      let totalDisc = 0;
      let amount = 0;
      if (
        this.adjustmentType.value === this.adjustmentRateEnum.percentageRate
      ) {
        totalDisc = standard * (discount / 100);
        amount = standard - totalDisc;
      } else {
        amount = standard - discount;
      }
      return amount.toFixed(2);
    },
    addMore() {
      this.__FeePricing.push({
        test: "",
        cptCode: "",
        modifier: "",
        standardRate: "",
        adjustmentDiscount: "",
        billRate: "",
        expectedReimbursement: "",
        isCreated: false,
      });
    },
    onDelete(index) {
      this.__FeePricing.splice(index, 1);
      this.render = false;
      this.rowNumber = -1;
      this.$nextTick(() => {
        this.render = true;
        this.__FeePricing.forEach((freePrice, index) => {
          this.$refs[`standardRate${index}`].$refs.inputRef._events.change[0](
            freePrice.standardRate
          );
          this.$refs[
            `adjustmentDiscount${index}`
          ].$refs.inputRef._events.change[0](freePrice.adjustmentDiscount || 0);
          this.onAmountChange(
            index,
            freePrice.standardRate,
            freePrice.adjustmentDiscount || 0
          );
          this.$refs[
            `expectedReimbursement${index}`
          ].$refs.inputRef._events.change[0](
            freePrice.expectedReimbursement || 0
          );
        });
      });
    },
    setupFeePricing() {
      this.__FeePricing = this.__FeePricing.map((item) => {
        item.loadInitialCurrencyValue = false;
        item.billRate = this.computeDiscount(
          item.standardRate,
          item.adjustmentDiscount
        );
        return item;
      });
    },

    processQueryCollectionPricing() {
      const fees = [...this.__FeePricing]
        .filter((item) => !item.isCreated)
        .map((pricing) => ({
          testId: pricing.test.value,
          cptCodeId: pricing.cptCode.value,
          modifier: pricing.modifier.value,
          standardRate: parseFloat(pricing.standardRate || 0),
          specialRate: parseFloat(pricing.adjustmentDiscount || 0),
          reimbursement: parseFloat(pricing.expectedReimbursement || 0),
        }));

      let collectionQueries = [];
      fees.forEach((fee) => {
        if (!fee.isCreated) {
          collectionQueries.push({
            addFeeSchedulePricing: {
              input: {
                type: "VbsFeeSchedulePricingInput!",
                value: fee,
                unincludeToFields: true,
              },
              mutationReturn: ["success"],
            },
          });
        }
      });
      return collectionQueries;
    },

    async updateFees() {
      try {
        const feesFormValidate = await this.validateFees();
        if (!feesFormValidate) {
          this.showNotifyMessage({
            message: "Please provide all necessary fields.",
            type: "danger",
          });
          return;
        }

        if (!this.validateCombinations()) {
          this.showNotifyMessage({
            message:
              "System does not accept similar test id, cpt, and modifier. Please provide a unique value from any of these identifier:  test id, or cpt, or modifier.",
            type: "danger",
          });
          return;
        }

        this.isSaving = true;
        const feeCollectionQueries = this.processQueryCollectionPricing();

        const params = {
          variables: {
            id: {
              type: "UUID!",
              value: this.$route.params.id,
              unincludeToFields: true,
            },
          },
          queries: {},
          collectionQueries: feeCollectionQueries,
        };
        await actions.updateFeeSchedule(params);

        this.showNotifyMessage({
          message: "Records has been saved.",
          type: "success",
        });
        this.isSaving = false;
        this.canEdit = false;
        this.$nextTick(() => {
          this.__FeePricing.forEach((index) => {
            index.isCreated = true;
          });
        });
      } catch (err) {
        this.isSaving = false;
        this.showNotifyMessage({
          message: "Problem has occurred while updating data.",
          type: "danger",
        });
      }
    },
    compareFeeScheduleObj() {
      let hasChanges = false;
      if (this.feePricing.length !== this.feePricingCopy.length) {
        hasChanges = true;
      }
      return hasChanges;
    },
    confirmDiscardChanges() {
      this.isModalVisible = false;
      this.canEdit = false;
      this.__FeePricing = JSON.parse(JSON.stringify(this.feePricingCopy));
    },
    cancelEdit() {
      if (this.compareFeeScheduleObj()) {
        this.isModalVisible = true;
      } else {
        this.canEdit = false;
      }
    },
  },
  async created() {
    this.setupFeePricing();
    this.feePricingCopy = JSON.parse(JSON.stringify(this.feePricing));
    this.$nextTick(() => {
      if (this.isEditMode) {
        this.canEdit = false;
        this.headers.splice(this.headers.length - 1, 0, {
          text: "Creation Date",
          align: "left",
          sortable: false,
          width: "15%",
        });
      }
    });
  },
};
