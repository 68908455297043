export default {
  // @Depracated, used in FeeSched need to move to SearchDataTableModal once single props is stable
  name: "SearchPaginationModal",
  props: {
    openModal: {
      type: Boolean,
      value: false,
    },
    modalTitle: String,
    sectionNote: String,
    tableHeader: [Array, Object],
    searchLabel: String,
    limitData: Array,
    relativeUrl: String,
    queryName: String,
    searchBy: { type: String, require: true },
    modelInstance: { type: Function, require: true },
    initialValue: Array
  },
  data() {
    return {
      canEdit: true,
      searchFilter: null,
      isLoading: false,
      dataCollection: [],
      currentLimit: 0,
      totalCount: 0,
      offset: null,
      numberOfSelected: 0,
      searchVal: "",
    };
  },
  watch: {
    async isLoading(val) {
      if (val) {
        await this.filterSearchModel(10, null);
        this.isLoading = false;
      }
    },
    openModal(val) {
      if (val) {
        this.dataCollection = [];
        this.offset = null;
        this.searchVal = "";
        this.isLoading = true;
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.openModal;
      },
      set(bool) {
        this.$emit("update:openModal", bool);
      },
    },
    getSelectedString() {
      return this.numberOfSelected;
    },
  },
  methods: {
    updateSelectedString() {
      this.numberOfSelected = this.$refs.searchDataTable.getSelectedString();
    },
    closeModal() {
      const selectedItems =  this.$refs.searchDataTable.getSelectedItems();
      this.canEditForm();
      this.$emit("closeModal", selectedItems);
    },
    async filterSearchModel(
      limit = null,
      offset = null,
      returnResults = false,
      order = null
    ) {
      if (typeof limit == "object" || limit == null) {
        limit = this.limit;
      }

      if (!order && this.defaultSort) order = this.defaultSort;
      try {
        this.$emit("loading", true);
        this.offset = offset;
        this.currentLimit = limit;
        let options = {
          relativeUrl: this.relativeUrl,
          limit,
          offset,
          filter: this.searchVal
            ? {
                ...this.buildFilters(this.searchBy),
                logicalOperator: this.searchLogicalOperand || "OR",
              }
            : null,
          includeDeleted: this.includeDeleted,
          additionalString:
            typeof this.additionalString == "object"
              ? this.additionalString
              : null,
          order,
          queryName: this.queryName,
        };
        if (this.limitData) options["limitData"] = this.limitData;

        const filterSearchResult = await this.modelInstance
          .api()
          .query(options, "to get all test");

        this.dataCollection = filterSearchResult.response.data;
        this.totalCount = this.dataCollection[0]?.totalCount || 0;
        if (filterSearchResult && filterSearchResult.response.status === 200) {
          if (returnResults) return filterSearchResult.response.data;
          else this.$emit("searchResults", filterSearchResult.response.data);
          this.$emit("loading", false);
        } else {
          this.showNotifyMessage({
            message: `Unable to fetch data.`,
            actions: [
              {
                label: "Retry",
                color: "white",
                handler: () => {
                  this.filterSearchModel(limit, offset, returnResults, order);
                },
              },
            ],
            type: "danger",
          });
        }
      } catch (err) {
        this.showNotifyMessage({
          message: `Unable to fetch data.`,
          actions: [
            {
              label: "Retry",
              color: "white",
              handler: () => {
                this.filterSearchModel(limit, offset, returnResults, order);
              },
            },
          ],
          type: "danger",
        });
      }
    },
    buildFilters(searchFilters) {
      const props = this.parseToArray(searchFilters);

      if (props.length == 1)
        return { [searchFilters.replace(/ /g, "")]: this.searchVal };
      else return props.reduce((pv, cv) => ((pv[cv] = this.searchVal), pv), {});
    },
    parseToArray(data) {
      return data.replace(/ /g, "").split(",");
    },
    async searchResults(result) {
      if (result.length) {
        this.totalCount = result[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.orders = result;
    },

    async searchData() {
      this.searchDebounce();
    },
    getSelected() {
      this.$emit(
        "returnSelected",
        this.$refs.searchDataTable.getSelectedItems()
      );
    },
    canEditForm(){
      if(this.initialValue || this.$route.name.includes("edit")){
        this.numberOfSelected = `${this.initialValue?.length | 0} record selected`;
        this.$nextTick(()=>{
          this.canEdit = false;
        })
      }
    }
  },

  async created() {
    this.searchFilter = this;
    this.searchDebounce = await this.debounce(() => {
      this.isLoading = true;
      this.dataCollection = [];
      this.offset = null;
    }, 600);

    this.canEditForm();
  },
};
