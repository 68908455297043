import AssociatedAccountsTable from "../../../../shared/AssociatedAccountsTable";
import startCase from "lodash/startCase";

export default {
  name: "FeeScheduleAssociation",
  components: {
    AssociatedAccountsTable,
  },
  props: {
    feeSched: {
      type: Object,
      require: true,
    },
    associated: {
      type: Array,
      required: true,
    },
  },
  computed: {
    scheduleType() {
      return this.feeSched.type;
    },
    haveModalSelector() {
      if (this.scheduleType.value == "") return false;
      return this.scheduleType.value === "SELF_PAY" ||
        this.scheduleType.value === "OTHER"
        ? false
        : true;
    },
    displayText() {
      if (this.scheduleType.value == "") return "Select Fee Schedule Type.";
      else if (
        this.scheduleType.value === "SELF_PAY" ||
        this.scheduleType.value === "OTHER"
      ) {
        return startCase(this.scheduleType.text.toLowerCase());
      } else {
        return `${startCase(
          this.scheduleType.value.toLowerCase()
        )}(s) to associate fee schedule.`;
      }
    },

    btnText() {
      const actionLbl = !this.feeSched.id ? "Add" : "Edit";
      const suffixLbl = !this.feeSched.id ? "" : "List";
      return `${actionLbl} ${this.scheduleType.text} ${suffixLbl}`;
    },
  },
  methods: {
    viewClient() {
      this.$emit("openModal");
    },
  },
};
