var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"fee-details-section",attrs:{"no-gutters":""}},[_c('v-loading',{attrs:{"displayModal":_vm.isSaving,"mesg":"Updating Fee Schedule Details...","type":"form"}}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('h3',{staticClass:"section-field-header"},[_vm._v("Fee Schedule Details")]),(!_vm.canEdit && _vm.isEditMode)?_c('span',{staticClass:"action ml-5",attrs:{"id":"fees_edit_btn"},on:{"click":function($event){_vm.canEdit = true}}},[_vm._v("EDIT")]):_vm._e()]),_c('v-form',{directives:[{name:"async-form",rawName:"v-async-form"}],ref:"form",attrs:{"lazy-validation":""}},[_c('v-data-table',{attrs:{"id":"feePricingTable","headers":_vm.headers,"items":_vm.__FeePricing,"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',{class:index == _vm.rowNumber ? "error-row" : ""},[_c('td',{staticClass:"pt-5"},[(_vm.render)?_c('v-custom-autocomplete',{ref:("testId" + index),attrs:{"id":("testId" + index),"name":"autocompleteTest","label":"Test Code ID","keyValue":"label","render":"<label>","searchBy":"label","modelInstance":_vm.testModel,"modelQueryOptions":{
                    relativeUrl: '/test/get-all-test',
                    queryName: 'tests',
                  },"rules":_vm.rules.required,"disable":_vm.isLoading || item.isCreated,"initialValue":item.test
                      ? {
                          id: { label: 'id', value: item.test.id },
                          label: item.test.label,
                          value: item.test.label,
                        }
                      : null,"dense":""},on:{"populateFields":function (e) { return _vm.populateCode(e, index); }}}):_vm._e()],1),_c('td',{staticClass:"pt-5"},[(_vm.render)?_c('v-custom-autocomplete',{ref:("cptCode" + index),attrs:{"id":("cptCode" + index),"name":"autocompleteCPT","label":"CPT Code","keyValue":"code","render":"<code>","searchBy":"code","modelInstance":_vm.cptModel,"modelQueryOptions":{
                    relativeUrl: '/cpt/get-all',
                    queryName: 'cptCodes',
                  },"rules":_vm.rules.required,"disable":_vm.isLoading || item.isCreated,"initialValue":item.cptCode
                      ? {
                          id: { label: 'id', value: item.cptCode.id },
                          label: item.cptCode.label,
                          value: item.cptCode.label,
                        }
                      : null,"dense":""},on:{"populateFields":function (e) { return _vm.populateCode(e, index); }}}):_vm._e()],1),_c('td',{staticClass:"pt-5"},[(_vm.render)?_c('v-custom-autocomplete',{ref:("modifier" + index),attrs:{"id":("modifier" + index),"name":"autocompleteModifier","label":"Modifier","keyValue":"modifierCode","render":"<modifierCode>","searchBy":"modifierCode","modelInstance":_vm.modifierModel,"modelQueryOptions":{
                    relativeUrl: '/modifier/get-all',
                    queryName: 'modifiers',
                  },"rules":[_vm.rules.allowableModifier],"disable":_vm.isLoading || item.isCreated,"modelCreateAction":_vm.actions.createModifier,"ableToCreateNoData":true,"createVariable":{
                    fieldName: 'modifierCode',
                    type: 'String!',
                  },"maxlength":2,"customMethodValidation":_vm.validateCreateModifier,"isToUpperSearch":"","initialValue":item.modifier
                      ? {
                          id: {
                            label: 'id',
                            value: item.modifier.id,
                          },
                          label: item.modifier.label,
                          value: item.modifier.label,
                        }
                      : null,"dense":""},on:{"populateFields":function (e) { return _vm.populateCode(e, index); },"clearFields":function($event){return _vm.clearModifier(index)}}}):_vm._e()],1),_c('td',[_c('v-currency-field',{ref:("standardRate" + index),attrs:{"id":("standardRate" + index),"prefix":"$","currencyValue":item.standardRate,"loadInitialValue":item.loadInitialCurrencyValue,"rules":_vm.rules.required.concat( [_vm.rules.notLessZero],
                    [_vm.rules.allowableDigit] ),"disabled":item.isCreated},on:{"update:currencyValue":function($event){return _vm.$set(item, "standardRate", $event)},"update:currency-value":function($event){return _vm.$set(item, "standardRate", $event)},"input":function (e) { return _vm.onAmountChange(index, e, item.adjustmentDiscount); }}})],1),_c('td',[_c('v-currency-field',{directives:[{name:"async-validate",rawName:"v-async-validate:debounce",value:(500),expression:"500",arg:"debounce"}],ref:("adjustmentDiscount" + index),attrs:{"id":("adjustmentDiscount" + index),"currencyValue":item.adjustmentDiscount,"prefix":_vm.isPrefix ? "$" : "","suffix":_vm.isPrefix ? "" : "%","disabled":_vm.isAdjustmentLock || item.isCreated,"loadInitialValue":item.loadInitialCurrencyValue,"rules":_vm.rules.required.concat( [_vm.rules.notLessZero],
                    [_vm.rules.allowablePercentage],
                    [_vm.rules.allowableDigit],
                    [function (e) { return _vm.rules.allowableAdjustmentRate(e, item.standardRate); }] )},on:{"update:currencyValue":function($event){return _vm.$set(item, "adjustmentDiscount", $event)},"update:currency-value":function($event){return _vm.$set(item, "adjustmentDiscount", $event)},"hasMounted":function($event){return _vm.populateCurrencies(item)},"input":function (e) { return _vm.onAmountChange(index, item.standardRate, e); }}})],1),_c('td',[_c('v-currency-field',{ref:("billRate" + index),attrs:{"id":("billRate" + index),"currencyValue":item.billRate,"prefix":"$","loadInitialValue":item.loadInitialCurrencyValue,"disabled":true,"rules":_vm.rules.required.concat( [_vm.rules.notLessZero],
                    [_vm.rules.allowableDigit] )},on:{"update:currencyValue":function($event){return _vm.$set(item, "billRate", $event)},"update:currency-value":function($event){return _vm.$set(item, "billRate", $event)},"hasMounted":function($event){return _vm.populateCurrencies(item)}}})],1),_c('td',[_c('v-currency-field',{ref:("expectedReimbursement" + index),attrs:{"id":("expectedReimbursement" + index),"prefix":"$","currencyValue":item.expectedReimbursement,"loadInitialValue":item.loadInitialCurrencyValue,"rules":_vm.rules.required.concat( [_vm.rules.notLessZero],
                    [_vm.rules.allowableDigit] ),"disabled":item.isCreated},on:{"update:currencyValue":function($event){return _vm.$set(item, "expectedReimbursement", $event)},"update:currency-value":function($event){return _vm.$set(item, "expectedReimbursement", $event)}}})],1),_c('td',[(item.created)?_c('div',{staticClass:"creation-date"},[_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.formatDate(item.created)))]),_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(("Last Updated: " + (_vm.formatDate(item.lastModified))))+" ")])]):_vm._e()]),_c('td',[(_vm.__FeePricing.length > 1 && !item.isCreated)?_c('v-icon',{staticClass:"circle-icon",attrs:{"color":"white","dense":""},on:{"click":function($event){return _vm.onDelete(index)}}},[_vm._v("mdi-window-close ")]):_vm._e()],1)])]}}])}),(_vm.canEdit || !_vm.isEditMode)?_c('div',{staticClass:"d-flex pa-3"},[_c('v-btn',{staticClass:"mr-auto",attrs:{"color":"primary","id":"add_more_btn"},on:{"click":_vm.addMore}},[_vm._v(" Add More Fee ")]),(_vm.canEdit || _vm.isEditMode)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{attrs:{"id":"dets_cancel_btn"},on:{"click":_vm.cancelEdit}},[_vm._v("Cancel")]),(_vm.hasNotCreatedItems >= 1)?_c('v-btn',{attrs:{"id":"fees_save_btn","color":"primary"},on:{"click":_vm.updateFees}},[_vm._v("Save")]):_vm._e()],1):_vm._e()],1):_vm._e()],1)],1)],1),_c('v-prompt-modal',{ref:"promptModal",attrs:{"buttons-elevation":0,"promptVisibility":_vm.isModalVisible,"message":"You haven't saved your entries.<br/>Are you sure you want to discard them?","positiveBtnTxt":"Keep Editing","negativeBtnTxt":"Discard","width":"fit-content","text-center":"","no-divider":""},on:{"update:promptVisibility":function($event){_vm.isModalVisible=$event},"update:prompt-visibility":function($event){_vm.isModalVisible=$event},"doCancel":_vm.confirmDiscardChanges,"doOk":function($event){_vm.isModalVisible = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }